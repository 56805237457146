/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

export enum documentTypes {
  frontRG = 'rg-frente',
  verseRG = 'rg-verso',
  cnh = 'cnh',
  contract = 'contrato',
  selfie = 'self',
  address = 'endereco',
  audio = 'audio',
  video = 'video',
  payStub = 'holerite',
}

export enum originTypes {
  ECOMMERCE = 'ECOMMERCE',
  LOTE = 'LOTE',
}

export enum AnnouncementTypes {
  general = 'GERAL',
  news = 'NOVIDADES',
  message = 'MENSAGEM',
}
export enum RelationshipAnnouncementStatuses {
  sent = 'ENVIADO',
  received = 'RECEBIDO',
  read = 'LIDO',
}
export enum proposalElectronicSignature {
  confia = 'CONFIA',
  clickSign = 'CLICKSIGN',
  dataPrev = 'DATAPREV',
  sabemi = 'SABEMI',
}
