import NotificationsIcon from '@mui/icons-material/Notifications';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Avatar, Badge, Button, IconButton, Menu, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import NotificationItem from 'components/NotificationItem';
import { compareDesc } from 'date-fns';
import { AnnouncementTypes, RelationshipAnnouncementStatuses } from 'enums';
import useBrowserNotification from 'hooks/useBrowserNotification';
import { announcementPayload } from 'interfaces/Announcement';
import { announcementSocketNotification } from 'interfaces/Socket';
import _ from 'lodash';
import { FixedSizeList } from 'react-window';
import socket from 'socket';

const useAnnouncements = () => {
  const [data, setData] = useState<Record<number, announcementSocketNotification>>({});
  const [selectedNotificationType, setSelectedNotificationType] = useState<announcementPayload['type']>(
    AnnouncementTypes.news,
  );
  const sorted = useMemo(() => {
    return Object.values(data)
      .filter((e) => {
        return e.type === selectedNotificationType;
      })
      .sort((a, b) => {
        return compareDesc(new Date(a.createdAt), new Date(b.createdAt));
      });
  }, [data, selectedNotificationType]);

  const setAnnouncements = useCallback(
    (param: announcementSocketNotification[]) => {
      setData(() => {
        return _.keyBy(param, 'announcementId');
      });
    },
    [setData],
  );

  const addAnnouncement = useCallback(
    (param: announcementSocketNotification) => {
      setData((oldState) => {
        const newData = oldState;
        newData[param.announcementId] = param;
        return { ...newData };
      });
    },
    [setData],
  );

  const setRead = useCallback(
    (announcementIds: number[] | undefined) => {
      if (!announcementIds) {
        return;
      }

      setData((oldState) => {
        const newData = oldState;

        const announcementIdsToUpdate = announcementIds.filter((announcementId) => {
          return newData[announcementId].status !== RelationshipAnnouncementStatuses.read;
        });
        announcementIdsToUpdate.forEach((announcementId) => {
          newData[announcementId].status = RelationshipAnnouncementStatuses.read;
        });
        socket.emit('announcementsRead', {
          announcementIds: announcementIdsToUpdate,
          readOn: new Date(),
        });
        return { ...newData };
      });
    },
    [setData],
  );

  return {
    allAnnouncements: Object.values(data),
    announcements: sorted,
    addAnnouncement,
    setRead,
    setAnnouncements,
    selectedNotificationType,
    setSelectedNotificationType,
  };
};

const NotificationsMenu = () => {
  const height = 450;

  const {
    announcements,
    setRead,
    allAnnouncements,
    setAnnouncements,
    addAnnouncement,
    selectedNotificationType,
    setSelectedNotificationType,
  } = useAnnouncements();

  const { notify } = useBrowserNotification();

  useEffect(() => {
    socket.emit('announcementsFetch', (data) => {
      if (data.length > 0) {
        const announcement = data.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setSelectedNotificationType(announcement[0].type);
      }
      setAnnouncements(data);
    });
    socket.on('announcement', (data, callback) => {
      addAnnouncement(data);
      notify('Nova notificação', { body: data.message });
      callback(data);
    });

    return () => {
      socket.removeListener('announcement');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnnouncements, addAnnouncement]);

  const NotificationMenuButtonRef = useRef(null);

  const [openNotificationsMenu, setOpenNotificationsMenu] = useState<boolean>(false);
  const countNewNotifications = (notificationsArr: announcementSocketNotification[]) => {
    return notificationsArr.filter((notification) => {
      return [RelationshipAnnouncementStatuses.sent, RelationshipAnnouncementStatuses.received].includes(
        notification.status,
      );
    }).length;
  };
  return (
    <Box>
      <IconButton
        ref={NotificationMenuButtonRef}
        onClick={() => {
          return setOpenNotificationsMenu(!openNotificationsMenu);
        }}
      >
        <Badge badgeContent={countNewNotifications(allAnnouncements)} color="error">
          <Avatar variant="square" sx={{ borderRadius: '10px' }}>
            <NotificationsIcon />
          </Avatar>
        </Badge>
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            sx: {
              height: 550,
              overflow: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            },
          },
        }}
        anchorEl={NotificationMenuButtonRef.current}
        open={openNotificationsMenu}
        onClose={() => {
          return setOpenNotificationsMenu(false);
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box width="100%" p="5px" display="flex" flexDirection="column" alignItems="center">
          <Button
            onClick={() => {
              return setRead(_.map(allAnnouncements, 'announcementId'));
            }}
          >
            Marcar todas como lidas
          </Button>
        </Box>
        <Tabs
          centered
          value={selectedNotificationType}
          onChange={(_e, value) => {
            setSelectedNotificationType(value);
          }}
          sx={{ mb: 1 }}
        >
          {Object.values(AnnouncementTypes).map((at) => {
            return <Tab label={at} value={at} sx={{ width: '33%' }} />;
          })}
        </Tabs>
        <FixedSizeList
          itemData={{ notifications: announcements, height, setRead, isModalOpen: openNotificationsMenu }}
          itemCount={announcements.length}
          itemSize={200}
          width="100%"
          height={height}
        >
          {NotificationItem}
        </FixedSizeList>
      </Menu>
    </Box>
  );
};

NotificationsMenu.propTypes = {};

export default NotificationsMenu;
